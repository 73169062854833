import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import { BrowserRouter, Redirect, Switch, Route } from "react-router-dom";
// import * as serviceWorker from "./serviceWorker";

// ReactDOM.render(<App />, document.getElementById('root'));

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to="/provider/onboarding" />}
      />
      <Route
        exact
        path="/provider/product/create"
        render={() => <Redirect to="/provider/onboarding" />}
      />
      <Routes />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
