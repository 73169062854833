import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import "antd/dist/antd.css";
import { Spinner } from "../src/components/spinner";

const ProvidersBasicInformationView = lazy(() =>
  import("./components/provider-onboarding/stages/BasicInformation")
);
const ProviderLicenseUploadView = lazy(() =>
  import("./components/provider-onboarding/stages/LicenseUpload")
);
const ProviderShortCodeView = lazy(() =>
  import("./components/provider-onboarding/stages/ShortCode")
);

const PasswordView = lazy(() =>
  import("./components/provider-onboarding/Password")
);
const PaymentView = lazy(() => import("./components/payment/Payment"));

const ProductInitView = lazy(() => import("./components/products/Init"));
const ProductDetailsView = lazy(() =>
  import("./components/products/stages/ProductDetails")
);
const SelectCategoryView = lazy(() =>
  import("./components/products/stages/SelectCategory")
);
const AccessChannelView = lazy(() =>
  import("./components/products/stages/AccessChannel")
);
const ContentConfigurationView = lazy(() =>
  import("./components/products/stages/ContentConfiguration")
);
const PaymentPlanView = lazy(() =>
  import("./components/products/stages/PaymentPlan")
);

const Loading = (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "80vh",
    }}
  >
    <Spinner size={30} color={"#36C4CE"} />
  </div>
);
// import "react-dates/initialize";
const Routes = () => (
  <div>
    {/*<Route exact path="/provider/onboarding" render={ props => <Create {...props}/>} />*/}
    <Route
      exact
      path="/provider/onboarding"
      render={(props) => (
        <Suspense fallback={Loading}>
          <ProvidersBasicInformationView {...props} />
        </Suspense>
      )}
    />
    <Route
      exact
      path="/provider/onboarding/:providerId"
      render={(props) => (
        <Suspense fallback={Loading}>
          <ProvidersBasicInformationView {...props} />
        </Suspense>
      )}
    />
    <Route
      exact
      path="/provider/onboarding/license"
      render={(props) => (
        <Suspense fallback={Loading}>
          <ProviderLicenseUploadView {...props} />
        </Suspense>
      )}
    />
    <Route
      exact
      path="/provider/onboarding/shortcodes"
      render={(props) => (
        <Suspense fallback={Loading}>
          <ProviderShortCodeView {...props} />
        </Suspense>
      )}
    />

    <Route
      exact
      path="/product/create"
      render={(props) => (
        <Suspense fallback={Loading}>
          <ProductInitView {...props} />
        </Suspense>
      )}
    />

    <Route
      exact
      path="/product/onboarding"
      render={(props) => (
        <Suspense fallback={Loading}>
          <ProductDetailsView {...props} />
        </Suspense>
      )}
    />

    <Route
      exact
      path="/product/onboarding/:productId"
      render={(props) => (
        <Suspense fallback={Loading}>
          <ProductDetailsView {...props} />
        </Suspense>
      )}
    />
    <Route
      exact
      path="/product/category"
      render={(props) => (
        <Suspense fallback={Loading}>
          <SelectCategoryView {...props} />
        </Suspense>
      )}
    />
    <Route
      exact
      path="/product/access-channels"
      render={(props) => (
        <Suspense fallback={Loading}>
          <AccessChannelView {...props} />
        </Suspense>
      )}
    />
    <Route
      exact
      path="/product/content-channel-configurations"
      render={(props) => (
        <Suspense fallback={Loading}>
          <ContentConfigurationView {...props} />
        </Suspense>
      )}
    />
    <Route
      exact
      path="/product/plans"
      render={(props) => (
        <Suspense fallback={Loading}>
          <PaymentPlanView {...props} />
        </Suspense>
      )}
    />

    <Route
      exact
      path="/email/verification/:token"
      render={(props) => (
        <Suspense fallback={Loading}>
          <PasswordView {...props} />
        </Suspense>
      )}
    />

    <Route
      exact
      path="/pay"
      render={(props) => (
        <Suspense fallback={Loading}>
          <PaymentView {...props} />
        </Suspense>
      )}
    />
  </div>
);

export default Routes;
